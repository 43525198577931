import { AxiosResponse } from "axios";
import { loginTypes } from "types/auth";
import BaseService from "../../base";

class OAuthService extends BaseService {
    routeName = '/oauth'

    /**
     * 
     * @param token string
     * @returns loginTypes.Response
     */
    withGoogle(token: string): Promise<AxiosResponse<loginTypes.Response>> {
        return this.http.post<loginTypes.Response>(`${this.routeName}/withGoogle`, { token: token })
    }

    /**
     * 
     * @param token 
     * @returns loginTypes.Response
     */
    withFacebook(token: string): Promise<AxiosResponse<loginTypes.Response>> {
        return this.http.post<loginTypes.Response>(`${this.routeName}/withFacebook`, { access_token: token })
    }
}

export default new OAuthService()