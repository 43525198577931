import React from "react";
import { useForm } from "react-hook-form";
import { XInput, XSubmit } from "components/shared"
import classes from "./style.module.scss"
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockIcon from '@material-ui/icons/Lock';
import { IxAlert } from "components/shared/xAlert";
import { loginTypes } from "types/auth";
import { AuthServices, OAuthServices } from "services";
import { XLoading } from "components/shared"
import { Ripple } from "components/shared/xLoading/templates"
import Cookies from "js-cookie"
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login'
import configs from "configs/config";
import { getLoginInfo } from "helpers";

interface ComponentProps {
    setAlert: (alertInfo: IxAlert) => void
    setRedirect: (uniqueCode: string) => void,
    assistantName: String,
    type: string
}

function LoginForm({ setAlert, setRedirect, assistantName, type }: ComponentProps) {
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    const [loadingStatus, setLoading] = React.useState(false);

    const renderComponents = () => {
            return (
                <div className={classes.loginContainer}>
                    <form className={classes.loginForm}>
                        <p className={classes.signin}>Use your XIOT account to signin</p>
                        <p className={classes.note}>By signing in, you are authorizing {assistantName} to control your devices</p>
                        {/* Inputs */}
                        <div className={classes.inputWrapper}>
                            <XInput iconComponent={<AlternateEmailIcon />} label="Email" type="text" register={register} required></XInput>
                            <div className={classes.helpers}>
                                <p className={classes.error}>{errors.email && <span>Email is required</span>}</p>
                            </div>
                        </div>
                        <div className={classes.inputWrapper}>
                            <XInput iconComponent={<LockIcon />} label="Password" type="password" register={register} required></XInput>
                        </div>
                        {/* End Inputs */}
                        <div className={classes.buttonWrapper}>
                            <XSubmit submitFunction={() => handleSubmit(onSubmit)} color="primary" label="Log in" />
                        </div>
                    </form>
                    {/* <div className={classes.providers}>
                        <div className={classes.googleLogin}>
                            <GoogleLogin
                                clientId={configs.GOOGLE_CLIENT_ID}
                                buttonText="Login with google"
                                onSuccess={onSuccessGoogleLogin}
                                // I prefer to just console.log it to track it in the logs, because
                                // this function will be called even if the user closed the popup - which's not really a failure - 
                                onFailure={(data) => console.log(data)}
                                cookiePolicy={'single_host_origin'}
                                isSignedIn={false}
                            />
                        </div>
                        <div className={classes.facebookLogin}>
                            <FacebookLogin
                                appId={configs.FACEBOOK_CLIENT_ID}
                                textButton="Login with facebook"
                                buttonStyle={{ height: '44px', fontSize: '12px', paddingTop: '14px', width: '100%' }}
                                fields="name,email,picture"
                                scope="public_profile, email"
                                callback={responseFacebook}
                                icon="fa-facebook" />
                        </div>
                    </div> */}
                </div>
            )
    }

    /** 
    ***************
    Submmition: 
    ***************
    */

    /** Submmition helper */
    const prepareForRedirect = (data: loginTypes.Response) => {
        Cookies.set('gahcod', data.data.code!, { overwrite: true });
        setLoading(false)
        setRedirect(data.data.code);
    }

    const onSubmit = async (data: loginTypes.Body) => {
        setLoading(true)
        const dataWithInfo: loginTypes.LoginWithInfo = {
            ...data,
            ...await getLoginInfo(window, type)
        }
        try {
            const req = await AuthServices.login(dataWithInfo);
            prepareForRedirect(req.data);
        } catch (err: any) {
            setLoading(false)
            setAlert({ message: err.response?.data?.message || 'Something went wrong', severity: "error" });
        }
    }

    const onSuccessGoogleLogin = async (data: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        try {
            setLoading(true)
            const loginResponse = data as GoogleLoginResponse;
            const req = await OAuthServices.withGoogle(loginResponse.accessToken);
            prepareForRedirect(req.data);
        } catch (err) {
            setLoading(false)
            setAlert({ message: 'Sorry, something went wrong', severity: "error" });
        }
    }

    const responseFacebook = async (data: ReactFacebookLoginInfo) => {
        setLoading(true)
        try {
            const req = await OAuthServices.withFacebook(data.accessToken);
            prepareForRedirect(req.data);
        } catch (err) {
            setLoading(false)
            setAlert({ message: 'Sorry, something went wrong', severity: "error" });
        }
    }

    return (
        <XLoading loadingStatus={loadingStatus} lMessage="Please wait, we're processing your request " LoadingType={<Ripple />}>
            {renderComponents()}
        </XLoading>
    )
}

export default LoginForm;