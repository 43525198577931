import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import classes from "./style.module.scss"

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface IxAlert {
    message: string,
    severity: Color
}

export default function XAlert({ message, severity }: IxAlert) {
    // Ref: https://material-ui.com/components/snackbars/#snackbar
    const [open, setOpen] = React.useState(false);
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    React.useEffect(() => {
        if (message !== '') {
            setOpen(true);
        }
    }, [message])

    return (
        <div>
            {/* show if open is true */}
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    classes={
                        {
                            root: classes.alert
                        }
                    }
                >
                    {message}
                </Alert>
            </Snackbar>
        </div >
    );
}