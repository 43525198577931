import React from "react"
import classes from "./style.module.scss"

function Home() {
    React.useEffect(() => {
        document.title = "Authenticate - XIOT"
    }, []);

    return (
        <div className={classes.googleAuthPage}>
            <img src="/images/xiot-logo.png" className={classes.logo} alt="xiot logo" />
        </div>
    )
}

export default Home;
