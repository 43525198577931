import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MuiThemeProvider } from "providers"
import { AuthContainer } from "containers";
import "assets/scss/main.scss"
import { Home } from "pages";
function App() {
  return (
    <BrowserRouter>
      <MuiThemeProvider>
        <Routes>
          <Route path="/auth/*" element={<AuthContainer />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
