import React from "react"
import classes from "./style.module.scss"
import { LoginForm } from "components/Auth"
import { XAlert } from "components/shared"
import { Color } from "@material-ui/lab/Alert";
import { IxAlert } from "components/shared/xAlert";
import { useSearchParams } from "react-router-dom";
import { checkAlexaAuthValidity } from "helpers";

function GoogleAuth() {
    const [alert, setAlert] = React.useState({ message: '', severity: 'error' });
    const [validQuery, setValidQuery] = React.useState(false);

    let [searchParams] = useSearchParams();

    React.useEffect(() => {
        document.title = "Authenticate - Alexa"
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check the query params: 
    React.useEffect(() => {
        const client_id = searchParams.get('client_id');
        const redirect_uri = searchParams.get('redirect_uri');
        const state = searchParams.get('state');
        const response_type = searchParams.get('response_type');
        if (checkAlexaAuthValidity(client_id, redirect_uri, state, response_type)) {
            setValidQuery(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.get('client_id'), searchParams.get('redirect_uri')])


    const showAlert = (alert: IxAlert) => {
        setAlert({ message: '', severity: '' })
        setAlert(alert)
    }

    const changeRedirect = (code: string) => {
        window.location.href = `${searchParams.get('redirect_uri')}?code=${code}&state=${searchParams.get('state')}` as string;
    }

    return (
        <div className={classes.googleAuthPage}>
            <img src="/images/xiot-logo.png" className={classes.logo} alt="xiot logo" />
            {alert.message !== '' ? (<XAlert message={alert.message} severity={alert.severity as Color} />) : ''}
            <div className={`${classes.animateLogin} ${classes.formContainer}`}>
                {validQuery ? <LoginForm type="ALEXA" assistantName="Alexa" setAlert={showAlert} setRedirect={changeRedirect} /> : <div className={classes.forbidden}>Sorry, We're unable to process your request.</div>}
            </div>
        </div>
    )
}

export default GoogleAuth;
