import { Route, Routes } from 'react-router-dom';
import { AlexaAuthPage, EmailConfirmation, GoogleAuthPage, Home } from "pages"
import ResetPassword from 'pages/resetPassword';

const AuthContainer = () => {
    return (
        <Routes>
            <Route path='googleAuth' element={<GoogleAuthPage />} />
            <Route path='alexaAuth' element={<AlexaAuthPage />} />
            <Route path='emailConfirmation/:token' element={<EmailConfirmation />} />
            <Route path='resetPassword/:token' element={<ResetPassword />} />
            <Route path='*' element={<Home />} />
        </Routes>
    );
};

export default AuthContainer