import axios, { AxiosInstance } from "axios"
import Cookies from "js-cookie"

class BaseService {
  private axiosInstance: AxiosInstance
  protected routeName: string = '/'
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_LINK
    })
  }
  get http() {
    // get stored token from the cookies
    const token = Cookies.get('authorization')
    // if there is a token, pass it to the authorzation header
    if (token) {
      this.axiosInstance.defaults.headers.authorization = "Bearer " + token
    }
    // return axios instance anyway.
    return this.axiosInstance
  }
}

export default BaseService