import React from "react";
import { RegisterOptions, useForm } from "react-hook-form";
import { XInput, XSubmit } from "components/shared"
import classes from "./style.module.scss"
import LockIcon from '@material-ui/icons/Lock';
import { resetTypes } from "types/auth";
import { AuthServices } from "services";
import { XLoading } from "components/shared"
import { Ripple } from "components/shared/xLoading/templates"
import { useParams } from "react-router";
import { passwordRules } from "shared/validationRules";

function ResetForm() {
    const { token } = useParams();
    const [message, setMessage] = React.useState('');

    const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: "onChange" });
    const [loadingStatus, setLoading] = React.useState(false);
    // Will always have the latest value of the password field, used for confirmation password field
    const currentPassword = watch('password');

    /** 
    ***************
    Validation Rules:
    ***************
    */
    const passwordConfirmationRules: RegisterOptions = {
        minLength: 6,
        validate: value => value === currentPassword
    }

    /** 
    ***************
    Submmition: 
    ***************
    */

    const onSubmit = async (data: resetTypes.formData) => {
        setLoading(true)
        try {
            const resetToken = token as string;
            await AuthServices.updateForgottenPassword({ ...data, token: resetToken });
            setLoading(false)
            setMessage('Your password has been changed successfully');
        } catch (err) {
            setLoading(false)
            setMessage('Sorry, we couldn\'t process your request');
        }
    }

    return (
        <XLoading loadingStatus={loadingStatus} lMessage="We're processing your request " LoadingType={<Ripple />}>
            {message !== '' ? (<p className={classes.invalidToken}>{message}</p>) :
                <div className={classes.resetContainer}>
                    <form className={classes.resetForm}>
                        <p className={classes.title}>Let's Reset it!</p>
                        {/* Inputs */}
                        <div className={classes.inputWrapper}>
                            <XInput iconComponent={<LockIcon />} label="Password" type="password" register={register} required rules={passwordRules}></XInput>
                            <div className={classes.helpers}>
                                <div className={classes.multipleErrors}>
                                    <p className={classes.error}>{errors.password?.type === 'required' && <span>Password is required</span>}</p>
                                    <p className={classes.error}>{errors.password?.type === 'minLength' && <span>Minimum length is 6 chars</span>}</p>
                                    <p className={classes.error}>{errors.password?.type === 'pattern' && <span>{errors.password.message}</span>}</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.inputWrapper}>
                            <XInput iconComponent={<LockIcon />} label="Password Confirmation" type="password" register={register} required rules={passwordConfirmationRules}></XInput>
                            <div className={classes.helpers}>
                                <div className={classes.multipleErrors}>
                                    <p className={classes.error}>{errors.passwordConfirmation?.type === 'required' && <span>Confirm Password is required</span>}</p>
                                    <p className={classes.error}>{errors.passwordConfirmation?.type === 'minLength' && <span>Minimum length is 6 chars</span>}</p>
                                    <p className={classes.error}>{errors.passwordConfirmation?.type === 'validate' && <span>Password and confirm password do not match</span>}</p>
                                </div>

                            </div>
                        </div>
                        {/* End Inputs */}
                        <div className={classes.buttonWrapper}>
                            <XSubmit submitFunction={() => handleSubmit(onSubmit)} customColor="green" label="Reset" />
                        </div>
                    </form>
                </div>
            }
        </XLoading>
    )
}

export default ResetForm;