import { useParams, useNavigate } from "react-router-dom";
import { XLoading } from "components/shared"
import { Ripple } from "components/shared/xLoading/templates"
import React from "react";
import classes from "./style.module.scss"
import { AuthServices } from "services";

function EmailConfirmation() {
    const { token } = useParams();
    const navigate = useNavigate();

    const [loadingStatus, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');

    // will only run in the first redner
    // I've used self invoking function to be able to await on the request. 
    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                await AuthServices.confirmEmail(token as string);
                setLoading(false);
                setMessage('Thank you! your account has been verified');
            } catch (err) {
                setLoading(false)
                setMessage('Sorry, we couldn\'t verify your identity')
            }
        })();
    }, [navigate, token]);

    return (
        <div className={classes.confirmationContainer}>
            <div className={classes.googleAuthPage}>
                <img src="/images/xiot-logo.png" className={classes.logo} alt="xiot logo" />
            </div>
            <XLoading loadingStatus={loadingStatus} lMessage="We're verifying your identity..." LoadingType={<Ripple />}>
                {message}
            </XLoading>
        </div>
    )
}


export default EmailConfirmation