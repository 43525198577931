import { RegisterOptions } from "react-hook-form";

// The shared react-form-hook rules: 
export const passwordRules: RegisterOptions = {
    minLength: 6,
    pattern: {
        value: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        message: "Must contain Number, capital and small characters"
    }
}

export const emailRules: RegisterOptions = {
    pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Entered value does not match email format"
    }
}