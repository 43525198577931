import configs from "configs/config";

// https://developers.google.com/assistant/identity/oauth2?oauth=implicit#handle_authorization_requests
const checkGoogleAuthValidity = (clientId: string | null, redirect_uri: string | null, state: string | null, response_type: string | null): boolean => {
    if (clientId !== configs.GOOGLE_CLIENT_ID) {
        return false;
    }
    
    if (!redirect_uri || !redirect_uri.startsWith('https://oauth-redirect.googleusercontent.com/r/' || !redirect_uri.endsWith(configs.GOOGLE_APP_ID))) {
        return false;
    };
    
    
    if (!state) {
        return false;
    }
    
    if (response_type !== 'code') {
        return false;
    }

    return true;
}

export default checkGoogleAuthValidity;