interface IConfigs {
    API_LINK: string,
    GOOGLE_CLIENT_ID: string,
    ALEXA_CLIENT_ID: string,
    FACEBOOK_CLIENT_ID: string,
    GOOGLE_APP_ID: string,
}
const configs: IConfigs = {
    API_LINK: process.env.REACT_APP_API_LINK!,
    GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
    ALEXA_CLIENT_ID: process.env.REACT_APP_ALEXA_CLIENT_ID!,
    GOOGLE_APP_ID: process.env.REACT_APP_GOOGLE_APP_ID!,
    FACEBOOK_CLIENT_ID: process.env.REACT_APP_FACEBOOK_CLIENT_ID!
}

export default configs