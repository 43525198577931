export enum loginDeviceSource {
    XIOT_APPLICATION = "XIOT_APPLICATION",
    WEB_APPLICATION = "WEB_APPLICATION",
    GOOGLE_AUTH_APPLICATION = "GOOGLE_AUTH_APPLICATION",
    ALEXA_AUTH_APPLICATION = "ALEXA_AUTH_APPLICATION"
}

export interface Body {
    email: string,
    password: string,
}
export interface deviceInfo {
    device: string
    source: loginDeviceSource.GOOGLE_AUTH_APPLICATION | loginDeviceSource.ALEXA_AUTH_APPLICATION
}

export interface LoginWithInfo extends Body, deviceInfo { }

export interface Response {
    data: {
        accessToken?: string, // added the optional mark to allow deleting it from the object.
        code: string
    }
}
