import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { XLoading } from "components/shared"
import { ResetForm } from "components/Auth";
import { Grid } from "components/shared/xLoading/templates"
import classes from "./style.module.scss"
import { AuthServices } from "services";

function ResetPassword() {
    const { token } = useParams();
    const navigate = useNavigate();

    const [loadingStatus, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');

    // will only run in the first redner
    // I've used self invoking function to be able to await on the request.
    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                await AuthServices.checkForgetPasswordToken(token as string);
                setLoading(false);
            } catch (err) {
                setLoading(false)
                setMessage('Sorry, we couldn\'t verify your identity')
            }
        })();
    }, [navigate, token]);

    return (
        <div className={classes.resetPageContainer}>
            <XLoading loadingStatus={loadingStatus} lMessage="We're processing your request..." LoadingType={<Grid />}>
                {message !== '' ? (<p className={classes.invalidToken}>{message}</p>) :
                    (<div className={classes.formContainer}> <ResetForm /></div>)}
            </XLoading>
        </div>
    )
}


export default ResetPassword