import { AxiosResponse } from "axios";
import { loginTypes, resetTypes } from "types/auth";
import BaseService from "../base";

class AuthService extends BaseService {
    routeName = '/auth'

    /**
     * 
     * @param Data loginTypes.body
     * @returns Promise<AxiosResponse<registerInterfaces.Response>>
     */
    login(Data: loginTypes.LoginWithInfo): Promise<AxiosResponse<loginTypes.Response>> {
        return this.http.post<loginTypes.Response>(`${this.routeName}/login`, Data)
    }

    /**
     * 
     * @param token 
     * @returns string 
     */
    confirmEmail(token: string): Promise<AxiosResponse<string>> {
        return this.http.post<string>(`${this.routeName}/confirmEmail`, { token })
    }


    /**
     * 
     * @param token 
     * @returns string 
    */
    checkForgetPasswordToken(token: string): Promise<AxiosResponse<string>> {
        return this.http.post<string>(`${this.routeName}/checkForgetPasswordToken`, { token })
    }

    /**
     * 
     * @param Data resetTypes.body
     * @returns string 
    */
    updateForgottenPassword(data: resetTypes.Body): Promise<AxiosResponse<string>> {
        return this.http.patch<string>(`${this.routeName}/updateForgetPassword`, data)
    }

}

export default new AuthService()