import configs from "configs/config";

// https://developer.amazon.com/en-US/docs/alexa/account-linking/configure-authorization-code-grant.html#tokens
const checkAlexaAuthValidity = (clientId: string | null, redirect_uri: string | null, state: string | null, response_type: string | null): boolean => {
    if (clientId !== configs.ALEXA_CLIENT_ID) {
        return false;
    }

    if (!redirect_uri || !checkRedirectUrlValid(redirect_uri)) {
        return false;
    };


    if (!state) {
        return false;
    }

    if (response_type !== 'code') {
        return false;
    }

    return true;
}


const checkRedirectUrlValid = (link: string) => {
    let status = false;
    switch (link) {
        case 'https://pitangui.amazon.com/api/skill/link/M32P5O8UDGMNWK':
            status = true;
            break;
        case 'https://layla.amazon.com/api/skill/link/M32P5O8UDGMNWK':
            status = true;
            break;
        case 'https://alexa.amazon.co.jp/api/skill/link/M32P5O8UDGMNWK':
            status = true;
            break;
        default:
            break;
    }

    return status;
}

export default checkAlexaAuthValidity;